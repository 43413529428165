import React,{useState} from "react";
import {Container, GridColumn, GridRow, Image , StepTitle, Popup,
  StepGroup,
  StepDescription,
  StepContent,
  
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Search,
  Segment,
  Step,} from "semantic-ui-react";
//import RegisterForm from "../../components/Auth/RegisterForm";
//import { Link , } from "react-router-dom";
//import LoginForm from "../../components/LoginForm";
//import { Slide } from "react-slideshow-image";
//import Auth from "./exterior/Auth";
import logo from "../../asets/png/logo.jpg";
//import zangano from "../../asets/png/zangano.png"
import favela from "../../asets/png/favela.jpg"
import atizapan from "../../asets/png/atizapan.jpg"
//import lguadalupe from "../../asets/png/lguadalupe.jpg"
import bramader from "../../asets/png/bramader.jpg"
import pedro from "../../asets/png/pedro.jpg"
import cielo4 from "../../asets/png/cielo4.png"
import cielo2 from "../../asets/png/cielo 2.png"
import fly300 from "../../asets/png/fly300.png"
import avat from "../../asets/png/avat.png"
//import Slider from "./slider/Slider";
//import RegisterWatt from "./RegisterWatt/RegisterWatt"
import "./Portada.scss";
import { from } from "@apollo/client";



export default function Portada  (){
    const [showLogin, setShowLogin] = useState (true);


    return (


    <>
  <Grid className="login" mobile={16} centered>
<GridRow className="miniatura" mobile={16}   computer={16}  >
<Image  src={cielo2}   tiny  />

<Image  src={bramader}   tiny />
<Image  src={pedro}   tiny  />
<Image  src={atizapan}   tiny  />
<Image  src={favela}   tiny />
<Image  src={cielo4}   tiny />

</GridRow>


<div className='photo' computer={16} mobile={16}>
    <h1>La Colme</h1>
    <Button inverted circular className="logo">
    <Popup
        className="logo"
        content="Entra al Eshop "
        position='top center'
        trigger={<Image src={fly300} size='tiny' circular href="https://ezstat.ru/RNdHH3"/>}
        
      />
    </Button>
    
      
      <p className="read">
      <h2>
         Hola Bienvenid@ 😀
        </h2>
         Click en la Abeja para Iniciar. 
      </p>
      
      </div>
      


       
       <div>
   
  </div>
       
  

<div placeholder  >
    <Grid columns={3}  stackable textAlign='center'  mobile={5}>
      

      <GridRow verticalAlign= "bottom" className=" instruc">
        <GridColumn moblie= {3}>
          <Header icon>
            <Icon name='search' />
            <h2>Encuentra lo que buscas, gran variedad de articulos en tu localidad.</h2>
          </Header>

          
        </GridColumn>

        <GridColumn moblie= {3} >
          <Header icon>
            <Icon name='whatsapp' />
            <h2>Contacta directamente por Whats App haciendo click en el icono de whatsapp.</h2>
          </Header>
         
        </GridColumn>
    
        
  

  <GridColumn moblie= {3} textAlign='center'>
          <Header icon>
            <Icon name='handshake outline' />
          <h2>Acuerda el pago y la forma de entrega con el vendedor. </h2>
          </Header>
         
        </GridColumn>
      </GridRow>
    </Grid>
  </div>
  <GridRow>

  <a href="https://ezstat.ru/RNdHH3" target="_blank">
  <Button  basic inverted color="blue">Entrar</Button> 
   </a>

  </GridRow>
  
  <Container fluid  className ="conta"  mobile={16} >


<h2> La Colme </h2>

<span >Eshop comunitaria, entra para comprar y vender articulos  de personas de tu municipio o colonia. </span >



</Container>
   

   
    </Grid>
    


       </>



    );
}